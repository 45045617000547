import React from 'react';
import { Seo } from '@app/components';
import {
  Contact,
  Footer,
  Header
} from '@app/components/template';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import blogBack from '@app/images/blog-back.jpg';

export default () => {
  const intl = useIntl();
    return (
      <>
        <Seo title={ intl.formatMessage({id: 'seo_title_contact'}) }
             description={ intl.formatMessage({id: 'seo_description_second'}) } />
        <div id="main" className="main-content-wraper">
          <div className="main-content-inner">
            <Header />

            <section className="breadcrumb-section"
                     style={ {
                       backgroundImage: `url(${ blogBack })`,
                       backgroundSize: 'cover',
                       backgroundPosition: 'center top',
                       backgroundRepeat: 'no-repeat'
                     } }>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-center breadcrumb-content">
                      <h2>
                        <FormattedMessage id="footer_contact" />
                      </h2>
                      <ul className="breadcrumb">
                        <li>
                          <Link to='/'>
                            <FormattedMessage id="footer_home" />
                          </Link>
                        </li>
                        <li>
                          <FormattedMessage id="footer_contact" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Contact />
            <Footer />
          </div>
        </div>
      </>
    );
  }